import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/projectsTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Find Communities Today was a project that I created to learn React. I've used Gatsby, TailwindCss, Airtable and MongoDB to create this website. It was really fun working on this and I've learned a lot.`}</p>
    <p>{`The site grew gradually. Initially, it was just a list of communities, then I added the search bar, then the pagination on the home page, then the random generator and finally the like/dislike and comment system.`}</p>
    <p>{`All the forms on the website are connected to Airtable and after submitting communities to Airtable, the process of moving the data from airtable to a markdown file took forever, so I wrote a quick python script using Jinja2 to get the data from Airtable and automatically create the markdown file on the communities folder.`}</p>
    <p>{`Unfortunately, this project didn't grow as much as I wanted - mostly due to the fact that I haven't really done any marketing for it. Still, it gets an occasional visit from time to time and that's why I keep it running.`}</p>
    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <ul>
      <li parentName="ul"><strong parentName="li">{`The Good`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Users are able to vote and comment on a community`}</li>
          <li parentName="ul">{`Created a script that gets data from airtable and turn into a markdown file that adds the community to the list`}</li>
          <li parentName="ul">{`Offers a nice appealing way to browse communities and even suggests communities related to the one open.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`The Bad`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Choosing a platform will just throw all the communities in that category - pagination would improve the look`}</li>
          <li parentName="ul">{`Viewing all categories page loads a page with two columns - this page doesn't look very appealing`}</li>
          <li parentName="ul">{`Adding/updating featured communities needs to be done manually on frontmatter for each community.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`The Ugly`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Community links that are too long break the sidebar a bit - should just create a redirect link`}</li>
          <li parentName="ul">{`Liking/Disliking a community from the sidebar doesn't add the vote on the page - but does on the db`}</li>
        </ul>
      </li>
    </ul>
    <h1 {...{
      "id": "info",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#info",
        "aria-label": "info permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Info`}</h1>
    <p><strong parentName="p">{`Website:`}</strong>{` `}<a parentName="p" {...{
        "href": "https://findcommunities.today/"
      }}>{`https://findcommunities.today/`}</a></p>
    <p><strong parentName="p">{`GitHub repo:`}</strong>{` Private Repository`}</p>
    <h1 {...{
      "id": "images",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#images",
        "aria-label": "images permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Images`}</h1>
    <p>{`Home Page
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ca31dd3279460db5ad23ef1da140b470/690e2/FCT-Home.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "146.4%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAdCAIAAAAl5NuSAAAACXBIWXMAAAsTAAALEwEAmpwYAAADdUlEQVQ4y4VU244bNwz1//9NH4o+BUUf+gF9CNLcdxN31/aMR3eJdxXSeLdp2kUIwhhKpCUdnsNDcmhm/RsjsYzW2CpZmZ7R9F8pNzv8dymjfF7q163drfXTUh5c+3QpwPrjYptWayu1ttYQYU14jvDd7V48ufeu1pE1ATeSpegp2/+mHYhldxatDVxwmwvXzYWUKwqAqaiKIPF0wZGse3gQtcbaSMV6bXh3v53O19NlSbmS6Ioxtlobkegs49oglYYsyHpAopTyeGu3BvrLT/HxMeaS5kL/Of5+5x5KqmqmZh1CjONexCxqByJugDtOxBp8dM5tztdarfdYYojB+9BaU7OSSwgxxBRTZpEDEufazExVifWrx0uEWDA3IdHHwNfMBbigstjRwSVRRc4gjeTAAwyy3lUHDJeAvlIBGmiRrIVDG3kVBUm2GQLpvjvQrg32k3Np52U9nS9/PZxKQyQVUeKRhzR+zTqxNGSY4YFZcm0iEw7A9e59DGFzPsYoMv7Ox6dQ7Y83fz6eL25zqjoAm10U1cE+QFqP95tzzoeUsqjmXDbvfYgpZ7P+4d27ZVlTLrlUYj4AUojphrboNcPqU0ilNBRVl5tPrVTIFVg0A4fSUqmj1SSjuDbovctAW7543Zqh9EKGJKdqG/Q2hQUkZ7YrdJJeuFfUUZxr69ZFBu+EMMW4XTeeLbh//3o5nwhpEL73L69eXY/HzXkEUOujz21He167Nth8dD7MFvT3b9+cTpeYSh0k6R9+/e3x/uhCKnVwbhTX52KWEMJ63dbNxZhk6iGm5CerJiM7IPI00XntMhjWnxl2zQSsGcYrLlkCGMoYLMS6ZAlorIbaUWwUx1xUVc2QZS0SYfCpDA7xktjXnZ6TcIncCGn1ORa4XXsXDQ5V3+StaoM40/ZvkkEMURss3oJL5bs369MM1M9LuVvK12v9vJSPl/zgKjPhNBGZrbVbq8xGn6338+JffzxV0nVdh3Zj2gUYU8rTYkwA0Lux7H0utZvxYKnFDOc1Hj2lxo24z7lnqsJMNPx5Eo6TkRgQnxm277HY24WOgXs3mS/Sf+wGxDh5FAPuazjVB8RATGNK7eGLftjhRWIRxW822vQfFJc2lI1jmg5WPLnsU3YftC/5ITncivimrgrKDQwVLaXGoeK8S733nYJMk5vErKp/A2z+lfQ4lW7VAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Home Page",
            "title": "Home Page",
            "src": "/static/ca31dd3279460db5ad23ef1da140b470/0eb09/FCT-Home.png",
            "srcSet": ["/static/ca31dd3279460db5ad23ef1da140b470/c59a0/FCT-Home.png 125w", "/static/ca31dd3279460db5ad23ef1da140b470/86700/FCT-Home.png 250w", "/static/ca31dd3279460db5ad23ef1da140b470/0eb09/FCT-Home.png 500w", "/static/ca31dd3279460db5ad23ef1da140b470/e9985/FCT-Home.png 750w", "/static/ca31dd3279460db5ad23ef1da140b470/1263b/FCT-Home.png 1000w", "/static/ca31dd3279460db5ad23ef1da140b470/690e2/FCT-Home.png 1265w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Community Page
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0450745bbd72899764fe98a9cdd043d6/690e2/FCT-makerlog.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "124%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAZCAIAAAC+dZmEAAAACXBIWXMAAAsTAAALEwEAmpwYAAACYUlEQVQ4y41U227bMAz1/3/Z9rTHYcOAAd1yadDEdizxTnqglDTp2nU9IGRL4pEo3oZ5pIhYX8FjJQu19a3NC4bw0AZr6P9hOgN/3dUfj1BJ1shdve525UaOQEQiYma6YillKaVCBYDjGR8noBtSnVmS/NqYWFcSqyQLKqntij/M/qbtg3R7zNVc1LqY5xTYkN3d1/CucK8jaoN7EMtSoSK5O4m5u/oq5v2NIir6LOYeHaw+mHsFIOK2vLKmJ07TebPd77aJ3a5/8+dwOBBRt1nMBzWrgD1a7TzLNyOWZSkNtdZyBVFadyOLWoXLYRdyRFp+h+cQ3WdEJ2spcH+zeZiv9+41j3t3s4iZd7Ij6/PNzdXB9kJbLPRuDkgk6bskd1+5h5lzHurubXqVv6fNyiQjCxADMrGyGKvl+D95GOVYdEgOC3PaYS0N7APyWGwCG8Qy3CzaROKdInqJ9mYLEhMWlna/6Ef4EauoX26W682ibRTt8s45GSoWk2vSq7s16e/v5fEvYbXhOI6bzeb3dv90mlAMRZHNItgy/tRXxEg9Rey6klvDWOPnQcfTEyBeXrOuXz5/Ouz34zybZSb7uioTLefj6VQBbg4DsqcJz1kGtUImj8f68P3beDyWWkvNgvMIZSrzNC9lKV2LG5l1N9ZxganAgsys4X4EH0Egm4lAJm8U0l/HOldsHSb9muTKvj0bsIM4iltL0t0sIzhKoIRYFtkZbXdOBeAc3SNDVWrmFjcR9UtLUiUiQATEfklmfHaW7Fm3NlRmnsBm9Ald7BJWd0ei1mFuXf1a5L0HZ/n8ARI8uiOQAexoAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Community Page",
            "title": "Community Page",
            "src": "/static/0450745bbd72899764fe98a9cdd043d6/0eb09/FCT-makerlog.png",
            "srcSet": ["/static/0450745bbd72899764fe98a9cdd043d6/c59a0/FCT-makerlog.png 125w", "/static/0450745bbd72899764fe98a9cdd043d6/86700/FCT-makerlog.png 250w", "/static/0450745bbd72899764fe98a9cdd043d6/0eb09/FCT-makerlog.png 500w", "/static/0450745bbd72899764fe98a9cdd043d6/e9985/FCT-makerlog.png 750w", "/static/0450745bbd72899764fe98a9cdd043d6/1263b/FCT-makerlog.png 1000w", "/static/0450745bbd72899764fe98a9cdd043d6/690e2/FCT-makerlog.png 1265w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Random Communities Page
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e70842333db18fc59e95337de01c147a/690e2/FCT-Random.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "146.4%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAdCAIAAAAl5NuSAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC/ElEQVQ4y51V2W4kRRDs//8aXhAI8QDiAQRC+JHDYHvHM56zjzqyKs9CWT3rxYul1bqVavUVVVERkdVDnNDM2v8OsZbQgF99eT2GV2AizASVbg/5/gyE1IT0tTFeB8cMKUMphbAelrqbqqp+GmydJrJUklgZkM9Z9+k6r70cYiCW50IWUWM1USMWQBa1dUQkQRIz6x80YkWSQdQKayF1gOj2bjvPS8xZ1Dl8/+Mvj7t9SIlZxNrmp58v+8McYkVktQGJYkzNTM3Bd7f/nC/jvARmbq198eU3dw+bEGOpVVu7/err/f3DuCwAoGYDEZfqbpl1qkQh5+ByoXa205JCykvKzAJqAUqtVJBYdEDiBMXMVJVEH2YdQQtZJkPR+5GOkYEskSHr48ynpJUtoVXWgUWQqLWm6hpA5YJUkYmYmAsykl8j+jVUKtXfVvJytaFUl1FcbdXOX6xwq9xQWmErbCju1lq62iE6MEuCIqKqxmKIlN0i+/NxvN1N94f576fpr9387jhDTjGlGALW6llSG0RVRFbBWmvbw3Tzx+M+EsSFEYRIhYSwlpIBMnjsViMcXJGWEFcwi7vNLFPmd7OcknSabZVT1Sd6bpQrGMpKQ5FVdF1Sewp6ycI+r/qgfdxO029ZlFgc3K1ytROUcTkeT+fd02FZAokqm4ibVFxtKeQTkFglqSjuc+lqW7MM9N2302ZzPp1OSGzafkg32/kAqVjnaQQxxMtl4t4FDoZSu0FWUW5+XU6nJcSASM3ab8vvu/E4z8HjqYY5zPN8meaUM0unnd/TJtFY82UJ4xwyVDGrWWOqwcPqHXZe8hQhQUlQkdXBIeUupmf7YdIRPBUZfambIBewys3TyrINcgYPDJAV0he01z3gudDP1yTih+e8xtP7eQVbb8mPwJ+sF1YhvQGcoTfG54PR14DXhL0FXFF7K372mln6vkn8Ftq5sLvSt1JifV/uE/bN+D8PP64hTjhmmYtOICjXdlPRnCGEmFJ6/ld4BNn3Ju5nVf0XIDyboF9EuUAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Random Communities Page",
            "title": "Random Communities Page",
            "src": "/static/e70842333db18fc59e95337de01c147a/0eb09/FCT-Random.png",
            "srcSet": ["/static/e70842333db18fc59e95337de01c147a/c59a0/FCT-Random.png 125w", "/static/e70842333db18fc59e95337de01c147a/86700/FCT-Random.png 250w", "/static/e70842333db18fc59e95337de01c147a/0eb09/FCT-Random.png 500w", "/static/e70842333db18fc59e95337de01c147a/e9985/FCT-Random.png 750w", "/static/e70842333db18fc59e95337de01c147a/1263b/FCT-Random.png 1000w", "/static/e70842333db18fc59e95337de01c147a/690e2/FCT-Random.png 1265w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Contact Page
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/912fbbff8d6d7f6d22ab52e3dd0da9e9/690e2/FCT-contact.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "146.4%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAdCAIAAAAl5NuSAAAACXBIWXMAAAsTAAALEwEAmpwYAAABsElEQVQ4y6VV7W7bMAz08++lhj3IsP4YhgH9SGtLong8UoXlOEkTd1hc4mAIhk68o2h6mA4aEe0m4K0gijXz9lkMp1VEAFBVBZz2PNVfj+XhKb9lbQ7FMS4zXZMB1FpTLrmIiFSRx7H+OYiusU0+n9KamldYqqbmTyX+5i1jrQ0wnqCwZWGk0StYQXeGE2aKBTxhoIeYC5weMOacjYyL8Aj34HrWJQYFpim1vg9GETEzvwmSG2TApGqs5FLmQtVa+TH/Z5ktFYmubZENoLX2X5mN1HU3zHPK0zhdpW1tPnpLtrFI7cKOdda1Gc7kCNpmZmMqQvri2ccxSlnbZn4+POPb98cfP6cWXvQjmd1PxNzBMDdVA0hf4O5Z+fsVL9n0JvlQFW/jtMhTUOACip6hxnC3TdlVUaS21uiu2NjxD8zkflWNc6nuJCtMerW9y76bXE5ku1927rJ9n+cx5flOdsv2fbK/6jl9xXPKZfli93iuqjs7bCZX7cW+37PxODf3yM5iFVSbmTBf0Yerzbh4eY1hOuhL5qv4oVB5nO1Oz7mM45RScj/+rPqcMtgc6BP2HQzNptdRUxUVAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Contact Page",
            "title": "Contact Page",
            "src": "/static/912fbbff8d6d7f6d22ab52e3dd0da9e9/0eb09/FCT-contact.png",
            "srcSet": ["/static/912fbbff8d6d7f6d22ab52e3dd0da9e9/c59a0/FCT-contact.png 125w", "/static/912fbbff8d6d7f6d22ab52e3dd0da9e9/86700/FCT-contact.png 250w", "/static/912fbbff8d6d7f6d22ab52e3dd0da9e9/0eb09/FCT-contact.png 500w", "/static/912fbbff8d6d7f6d22ab52e3dd0da9e9/e9985/FCT-contact.png 750w", "/static/912fbbff8d6d7f6d22ab52e3dd0da9e9/1263b/FCT-contact.png 1000w", "/static/912fbbff8d6d7f6d22ab52e3dd0da9e9/690e2/FCT-contact.png 1265w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      